/** THIS IS AN AUTOGENERATED FILE **/
.MarkdownText {
  display: block;
  white-space: pre-wrap;
}
.MarkdownText--inline {
  display: inline;
  white-space: normal;
}
.MarkdownText ul,
.MarkdownText ol {
  padding: 0 0 0 30px;
}
.MarkdownText ul > li {
  list-style-type: disc;
}
.MarkdownText strong {
  font-weight: 500;
}
.MarkdownText--content-block {
  margin: 0 0 20px 0;
}
.MarkdownText--content-block:last-child {
  margin-bottom: 0;
}
.MarkdownText--mention {
  display: inline-block;
  padding: 0 2px;
  border-radius: 2px;
  background: rgba(137, 139, 169, 0.15);
}
.MarkdownText a {
  color: #0168b3;
  text-decoration: underline;
}
