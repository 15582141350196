.GoalAlignmentsIndicator {
  color: rgba(53, 55, 74, 0.8);
}
.GoalAlignmentsIndicator--icon {
  font-size: 14px;
  margin-right: 2px;
}
.GoalAlignmentsIndicator--count {
  font-size: 12px;
  padding-top: 4px;
}
