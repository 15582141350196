/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.Tooltip {
  width: 200px;
  background-color: #35374a;
  padding: 15px;
  border-radius: 10px;
  left: calc(100% + 20px);
  top: -0.5em;
  position: absolute;
  color: white;
  z-index: 100;
}
@media (max-width: 900px) {
  .Tooltip {
    width: auto;
    left: 0;
    top: auto;
    bottom: calc(100% + 20px);
    padding: 13px 20px;
  }
}
.Tooltip--pointer,
.Tooltip--pointer-cutout {
  position: absolute;
  width: 0;
  height: 0;
  border: 25px solid;
  left: -20px;
  top: 34px;
  border-width: 10px 20px 10px 0;
  border-color: transparent #35374a;
}
@media (max-width: 900px) {
  .Tooltip--pointer,
  .Tooltip--pointer-cutout {
    left: 15px;
    top: auto;
    bottom: -12px;
    border-width: 15px 10px 0px 10px;
    border-color: #35374a transparent;
  }
}
.Tooltip--above {
  width: auto;
  left: 0;
  top: auto;
  bottom: calc(100% + 20px);
  padding: 13px 20px;
}
.Tooltip--above .Tooltip--pointer,
.Tooltip--above .Tooltip--pointer-cutout {
  left: 15px;
  top: auto;
  bottom: -12px;
  border-width: 15px 10px 0px 10px;
  border-color: #35374a transparent;
}
