.GoalAlignmentList {
  margin-bottom: 10px;
}
.GoalAlignmentList--header {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 5px;
}
.GoalAlignmentList--item.Row {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  border: none;
  padding: 10px 20px 10px 10px;
  margin-left: -10px;
}
