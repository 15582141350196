.LeveledConversation {
  margin-bottom: 15px;
  margin-top: 15px;
}
.LeveledConversation--profile {
  color: rgba(53, 55, 74, 0.8);
  font-size: 60px;
}
.LeveledConversation--author {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  display: inline;
}
.LeveledConversation--author-deactivated {
  display: inline;
  margin-left: 5px;
}
.LeveledConversation--content {
  text-align: left;
  margin: 6px 0 0 12px;
}
.LeveledConversation--messages {
  max-width: 100%;
}
.LeveledConversation--reply {
  width: 100%;
}
.LeveledConversation--reply-text,
.LeveledConversation--reply-text-nested {
  color: #0168b3;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-left: 80px;
  cursor: pointer;
}
.LeveledConversation--reply-text-nested {
  margin-left: 160px;
}
.LeveledConversation--reply-form {
  margin-left: 70px;
}
.LeveledConversation--reply-form-nested {
  margin-left: 140px;
}
.LeveledConversation--reply-form .ProfilePic {
  margin-right: 10px;
  margin-left: 6px;
}
.LeveledConversation--reply-form .LeveledConversation--reply-text-editor {
  min-width: 0;
}
.LeveledConversation--reply-form .DraftEditor-root {
  max-height: 200px;
  overflow-y: auto;
}
@media (max-width: 500px) {
  .LeveledConversation--reply-form .ProfilePic,
  .LeveledConversation--reply-form .TextEditor--emoji-toggle {
    display: none;
  }
}
.LeveledConversation--reply-action-row {
  margin: 8px 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}
.LeveledConversation--reply-action-row .SavingIndicator {
  margin-right: 8px;
}
.LeveledConversation--reply-action-row-active {
  max-height: 40px;
}
.LeveledConversation--send-button {
  margin-bottom: 0;
  padding: 0 10px;
  height: 30px !important;
  line-height: 30px !important;
}
.LeveledConversation--author-private-indicator {
  margin-left: 5px;
  color: rgba(53, 55, 74, 0.8);
}
.LeveledConversation--own-private-indicator {
  margin-left: 5px;
  color: rgba(53, 55, 74, 0.8);
}
