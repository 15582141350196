/** THIS IS AN AUTOGENERATED FILE **/
.GoalConversationSection--collapsable-header {
  cursor: pointer;
  font-weight: 500;
}
.GoalConversationSection--collapsable-header .ExpandIndicator {
  opacity: 0.5;
}
.GoalConversationSection--collapsable-header:hover .ExpandIndicator {
  opacity: 1;
}
.GoalConversationSection--collapsable-header-title {
  text-align: left;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.GoalConversationSection--collapsable-header-subtitle {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  text-align: left;
}
.GoalConversationSection--comments {
  margin-bottom: 16px;
}
.GoalConversationSection--comments-collaborator {
  font-weight: 600;
}
.GoalConversationSection--comments-comment {
  margin-top: 10px;
}
.GoalConversationSection--comments-comment-date {
  font-size: 14px;
  color: rgba(53, 55, 74, 0.45);
}
.GoalConversationSection--reply-form {
  width: 100%;
  margin-top: 20px;
}
.GoalConversationSection--reply-form .ProfilePic {
  margin-right: 10px;
  margin-left: 6px;
}
.GoalConversationSection--reply-form .GoalConversationSection--reply-text-editor {
  min-width: 0;
}
.GoalConversationSection--reply-form .DraftEditor-root {
  max-height: 200px;
  overflow-y: auto;
}
@media (max-width: 500px) {
  .GoalConversationSection--reply-form .ProfilePic,
  .GoalConversationSection--reply-form .TextEditor--emoji-toggle {
    display: none;
  }
}
.GoalConversationSection--reply-action-row {
  margin: 8px 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}
.GoalConversationSection--reply-action-row .SavingIndicator {
  margin-right: 8px;
}
.GoalConversationSection--reply-action-row-active {
  max-height: 40px;
}
.GoalConversationSection--send-button {
  margin-bottom: 0;
  padding: 0 10px;
  height: 30px !important;
  line-height: 30px !important;
}
