.SurveyQuestionConversation .ConversationMessage {
  background-color: #fff;
}
.SurveyQuestionConversation--title {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.SurveyQuestionConversation--question-number {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-right: 5px;
}
.SurveyQuestionConversation--question-optional {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: rgba(53, 55, 74, 0.8);
}
.SurveyQuestionConversation--title {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-bottom: 5px;
  text-align: left;
}
.SurveyQuestionConversation--description {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-bottom: 20px;
  text-align: left;
}
.SurveyQuestionConversation .LabeledEmployeePic--name {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.SurveyQuestionConversation .LabeledEmployeePic--date {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.SurveyQuestionConversation .ProfilePic {
  min-width: 62px;
  min-height: 62px;
  margin-right: 10px;
}
.SurveyQuestionConversation .ProfilePic--deactivated {
  font-size: 65px;
}
.SurveyQuestionConversation .Avatar {
  min-width: 62px;
  width: 62px;
  min-height: 62px;
  height: 62px;
}
.SurveyQuestionConversation .SurveyQuestion {
  margin: 10px 0 24px;
}
.SurveyQuestionConversation .MultipleChoice--button {
  opacity: 1 !important;
}
.SurveyQuestionConversation .Conversation {
  margin-left: 50px;
}
.SurveyQuestionConversation--copy-button {
  margin-top: -15px;
}
