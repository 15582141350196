/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
/** THIS IS AN AUTOGENERATED FILE **/
.QuestionFields {
  align-items: stretch;
}
.QuestionFields--item {
  min-width: 150px;
  display: block;
  opacity: 1;
  transition: 0.3s;
  width: 100%;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: normal;
}
.QuestionFields--item,
.QuestionFields--item .InputField input,
.QuestionFields--item .InputField textarea,
.QuestionFields--item .RichTextEditor {
  text-align: left;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: normal;
}
.QuestionFields--item .InputField .InputField--before-content {
  margin-left: -5px;
}
.QuestionFields--item .TextEditor .TextEditor--editor {
  padding-left: 15px !important;
}
.QuestionFields--item .RichTextEditor {
  min-height: 54px;
}
.QuestionFields--item .RichTextEditor .public-DraftEditorPlaceholder-root {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.QuestionFields--item .RichTextEditor .DraftEditor-root {
  padding-top: 3px;
}
.QuestionFields--item .Typeahead .Select-control {
  border-radius: 3px;
}
.QuestionFields--item .Typeahead.is-open .Select-control {
  border-radius: 3px 3px 0 0;
}
.QuestionFields--item .Typeahead.is-open .Select-menu-outer {
  border-radius: 0 0 3px 3px;
}
.QuestionFields--item .AccordionSelect {
  margin: 0;
}
.QuestionFields--label {
  white-space: pre-wrap;
  margin-bottom: 0.75rem;
}
.QuestionFields--item-without-field .QuestionFields--label {
  margin-bottom: 0;
}
.QuestionFields--label-optional {
  margin-left: 8px;
  font-weight: normal;
  opacity: 0.8;
}
.QuestionFields--container {
  display: flex;
  align-items: center;
}
.QuestionFields--container .QuestionFields--item {
  padding-right: 10px;
}
.QuestionFields--container .QuestionFields--item:last-child {
  padding-right: 0px;
}
.QuestionFields--row-container {
  display: flex;
  align-items: center;
  padding-top: 25px;
}
