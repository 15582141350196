/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/** THIS IS AN AUTOGENERATED FILE **/
.TypeaheadRowWithAvatar {
  height: 55px;
  color: #35374a;
}
.TypeaheadRowWithAvatar:hover {
  color: #0168b3 !important;
}
.TypeaheadRowWithAvatar--name {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-left: 8px;
}
.Select--multi .Select-control .TypeaheadRowWithAvatar {
  height: 40px;
}
.Select--multi .Select-control .TypeaheadRowWithAvatar--name {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-left: 3px;
}
.Select--single .Select-control .TypeaheadRowWithAvatar {
  height: 40px;
}
.Select--single .Select-control .TypeaheadRowWithAvatar--name {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.Select--single .Select-control .TypeaheadRowWithAvatar .Avatar {
  height: 35px;
  width: 35px;
  margin-right: 9px;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
}
.Select--single .Select-control .TypeaheadRowWithAvatar .TypeaheadRowWithAvatar--single-close {
  color: #35374a;
}
.Select--single .Select-control .TypeaheadRowWithAvatar .TypeaheadRowWithAvatar--single-close:hover {
  color: #D0021B;
}
.Select--single .Select-control .TypeaheadRowWithAvatar--clearable {
  background-color: unset;
  color: #3e4543;
}
.TypeaheadRowWithAvatar--email {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  margin-left: 8px;
}
.TypeaheadRowWithAvatar--message {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-align: right;
}
.TypeaheadRowWithAvatar--icon {
  padding-left: 5px;
  color: #e1e2ea;
  cursor: pointer;
}
.TypeaheadRowWithAvatar--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.TypeaheadRowWithAvatar--clearable {
  background-color: rgba(53, 55, 74, 0.05);
  color: #35374a;
  padding: 0 5px;
  margin: 5px;
  border-radius: 19.5px;
}
