/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.ShareModal .ShareModal--header {
  text-align: center;
  margin-top: 10px;
  max-width: 50%;
}
.ShareModal .ShareModal--message {
  text-align: center;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.ShareModal .ShareModal--pseudoheader {
  margin: 12px auto;
}
.ShareModal .ShareModal--recipients-select,
.ShareModal .TextEditor {
  width: 100%;
  margin-bottom: 35px;
}
.ShareModal .ShareModal--preview-container {
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
}
.ShareModal .ShareModal--button-container {
  width: 300px;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}
