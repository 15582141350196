/** THIS IS AN AUTOGENERATED FILE **/
.FeedbackItemTitle {
  margin-bottom: 5px;
}
.FeedbackItemTitle > * {
  margin-right: 10px;
}
.FeedbackItemTitle--reviewer-name {
  color: #35374a;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.FeedbackItemTitle--date {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: #35374a;
  opacity: 0.7;
}
.FeedbackItemTitle--type {
  color: #35374a;
  background-color: #ebebed;
  border-radius: 15px;
  height: 24px;
  padding: 3px 8px;
  margin-left: 10px;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
