/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/** THIS IS AN AUTOGENERATED FILE **/
.Table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  text-align: left;
}
.Table--header {
  display: table-header-group;
  border: 0 none;
}
.Table--body {
  display: table-row-group;
  background-color: white;
}
.Table--row {
  display: table-row;
}
.Table--body-row {
  height: 120px;
  color: #35374a;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  border-left: 1px solid #e1e2ea;
  border-right: 1px solid #e1e2ea;
  border-top: 1px solid #e1e2ea;
  border-bottom: 1px solid #e1e2ea;
}
.Table--body-row:first-child {
  border-radius: 3px 3px 0 0;
}
.Table--body-row:last-child {
  border-radius: 0 0 3px 3px;
}
.Table--cell {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 10px 10px 22px;
}
.Table--header-cell {
  color: #35374a;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  text-align: left;
  background-color: #f6f6f6;
  box-shadow: 0px 1px 0px 0px #e1e2ea !important;
}
.Table--header-cell:last-child {
  box-shadow: 2px 0px 0px 0px #f6f6f6, 0px 1px 0px 0px #e1e2ea !important;
}
.Table--row-interactive {
  cursor: pointer;
  transition: box-shadow 0.2s ease-out;
}
.Table--row-interactive:hover {
  background-color: #f9f9fa;
}
.Table--row-no-interactive {
  background-color: white;
  width: 0.1%;
}
.Table--row-interactive-indicator {
  vertical-align: middle;
  text-align: center;
  font-size: 24px;
  width: 0.1%;
  padding-left: 0;
  color: rgba(53, 55, 74, 0.8);
}
.Table--mobile-row-header,
.Table--mobile-header {
  display: none;
}
@media (max-width: 992px) {
  .Table--responsive {
    display: block;
    font-size: 16px;
  }
  .Table--responsive .Table--header {
    display: none;
  }
  .Table--responsive .Table--body {
    display: block;
  }
  .Table--responsive .Table--row {
    display: flex;
    padding: 10px 10px 10px 0;
    border-bottom: none;
  }
  .Table--responsive .Table--row:last-child {
    border-bottom: 1px solid #e1e2ea;
  }
  .Table--responsive .Table--body-row {
    text-align: left;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .Table--responsive .Table--cell {
    vertical-align: middle;
    display: flex;
    padding: 5px;
  }
  .Table--responsive .Table--cell .Table--mobile-header {
    display: inline;
    color: #35374a;
    padding-left: 86px;
    padding-right: 5px;
    font-weight: 400;
  }
  .Table--responsive .Table--cell .Table--mobile-row-header {
    font-size: 16px;
    padding-bottom: 5px;
    display: block;
  }
  .Table--responsive .Table--header-cell {
    color: #35374a;
    font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0.04166667em;
    font-size: 0.75rem;
    line-height: 2;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }
  .Table--responsive .Table--row-interactive {
    cursor: pointer;
    transition: box-shadow 0.2s ease-out;
  }
  .Table--responsive .Table--row-interactive:hover {
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  }
  .Table--responsive .Table--row-interactive-indicator {
    position: absolute;
    width: 20px;
    padding: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
