/** THIS IS AN AUTOGENERATED FILE **/
.BaseEditableQuestion--container {
  border-radius: 3px;
  border: 1px solid #e1e2ea;
}
.BaseEditableQuestion--container > :last-child {
  border-radius: 0 0 3px 3px;
}
.BaseEditableQuestion--error {
  border-color: #F04C5D;
}
.BaseEditableQuestion--title-field {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700 !important;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.BaseEditableQuestion--title-field .RichTextEditor {
  max-width: 1392px;
}
.BaseEditableQuestion--description-field {
  max-width: 1392px;
}
.BaseEditableQuestion--description-field.TextEditor .TextEditor--editor {
  padding-top: 0;
}
.BaseEditableQuestion--description-field .megadraft-editor .DraftEditor-root {
  font-size: 16px;
}
.BaseEditableQuestion--optional-slider {
  padding-top: 10px;
}
.BaseEditableQuestion--prompt-editor {
  position: relative;
  border-top: 1px solid #e1e2ea;
  padding: 15px;
  background: #fff;
}
.BaseEditableQuestion--prompt-editor,
.BaseEditableQuestion--prompt-editor textarea {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.BaseEditableQuestion--prompt-editor textarea {
  padding: 0;
  height: auto;
  min-height: 0;
  margin-left: -5px;
}
.BaseEditableQuestion--prompt-editor .InputClearButton {
  position: absolute;
  top: 0;
  right: 0;
}
.BaseEditableQuestion--prompt-label {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-bottom: 5px;
}
.BaseEditableQuestion--prompt-field {
  align-items: center;
}
.BaseEditableQuestion button:focus,
.BaseEditableQuestion button:active {
  background-color: transparent;
}
