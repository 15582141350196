/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/** THIS IS AN AUTOGENERATED FILE **/
.ReviewStatus {
  border-radius: 15px 15px 15px 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  max-width: 100px;
}
.ReviewStatus span {
  padding: 0 10px;
  vertical-align: middle;
}
.ReviewStatus--complete {
  background: #e9f6f3;
  color: #1e937d;
}
.ReviewStatus--incomplete {
  background: #fff1ee;
  color: #e1694f;
}
.ReviewStatus--expired {
  background: #f3f3f6;
  color: #7b7d98;
}
.ReviewStatus--not-started {
  background: #ebebed;
  color: #303243;
}
