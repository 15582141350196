/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
/** THIS IS AN AUTOGENERATED FILE **/
.AddQuestion {
  border: 1px dashed #e1e2ea;
  border-radius: 3px;
  background: #FFF;
  margin: 35px 0px;
  padding: 20px 0px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.2s ease-out, color 0.2s ease-out;
}
.AddQuestion:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}
.AddQuestion .Tooltip {
  left: calc(50% - 100px);
  bottom: calc(100% + 10px);
  width: auto;
  background-color: white;
  border: 1px solid #e1e2ea;
  padding: 0;
  border-radius: 0;
}
.AddQuestion .Tooltip .Tooltip--pointer,
.AddQuestion .Tooltip .Tooltip--pointer-cutout {
  left: calc(50% - 10px);
  border-width: 12px 10px 0px 10px;
  background-color: transparent;
}
.AddQuestion .Tooltip .Tooltip--pointer {
  bottom: -13px;
  border-color: #e1e2ea transparent;
}
.AddQuestion .Tooltip .Tooltip--pointer-cutout {
  bottom: -11px;
  border-color: white transparent;
}
.AddQuestion--menu {
  border-radius: 5px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.15s ease-out;
  background-color: #fff;
  padding: 5px !important;
}
.AddQuestion--menu-visible {
  opacity: 1;
  transform: scale(1);
}
.AddQuestion--menu-option {
  width: 100%;
  padding: 3px 15px;
  height: 40px;
  align-items: center;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  background: none;
  border: 0;
}
.AddQuestion--menu-option:hover {
  color: #0168b3;
  background-color: #f6f6f6;
}
.AddQuestion,
.AddQuestion--menu-option {
  color: #35374a;
}
.AddQuestion--icon {
  height: 24px;
  margin-right: 15px;
}
.AddQuestion--extra-hover-area {
  position: absolute;
  width: 100%;
  height: 10px;
  bottom: -10px;
}
.AddQuestion--add-label {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  color: #0168b3;
  display: flex;
  align-items: center;
}
