/** THIS IS AN AUTOGENERATED FILE **/
.GoalProgressSlider {
  height: 10px !important;
  background-color: transparent;
  border: 1px solid #d8d9d9;
  padding: 0;
}
.GoalProgressSlider::before {
  background-color: transparent;
}
.GoalProgressSlider .rc-slider {
  margin-top: -5px;
}
.GoalProgressSlider .rc-slider-rail,
.GoalProgressSlider .rc-slider-track,
.GoalProgressSlider .rc-slider-step {
  margin-top: 5px;
  border-radius: 9px;
}
.GoalProgressSlider .rc-slider-rail {
  background-color: white;
}
.GoalProgressSlider .rc-slider-track {
  background-color: #6a7690;
}
.GoalProgressSlider .rc-slider-handle {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  height: 20px;
  background-color: #35374a;
  color: white;
}
