/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.BreadcrumbAndMenuHeader {
  width: 100%;
  align-items: flex-start;
}
.BreadcrumbAndMenuHeader .MoreButtonsMenu {
  margin-left: 40px;
}
.BreadcrumbAndMenuHeader--action-button {
  color: #0168b3;
  height: 30px;
  border-radius: 50px;
  transition: opacity 0.3s;
  cursor: pointer;
  padding: 0 5px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  min-width: 130px;
}
.BreadcrumbAndMenuHeader--action-button i {
  margin-right: 5px;
}
@media (max-width: 500px) {
  .BreadcrumbAndMenuHeader--action-button {
    font-size: 12px;
  }
  .BreadcrumbAndMenuHeader--action-button i {
    display: none;
  }
}
.BreadcrumbAndMenuHeader--header-center h2,
.BreadcrumbAndMenuHeader--header-center h4 {
  text-align: center;
}
.BreadcrumbAndMenuHeader--header {
  min-width: auto;
  flex-basis: auto;
  flex-grow: 0;
}
