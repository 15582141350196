/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.CreateOrEditGoalFields .QuestionFields {
  margin-top: 35px;
}
.CreateOrEditGoalFields .QuestionFields .QuestionFields--label {
  text-transform: uppercase;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.04166667em;
  font-size: 0.75rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.CreateOrEditGoalFields .AccordionSelect {
  height: 280px;
}
.CreateOrEditGoalFields .AccordionSelect .AccordionExpandingItem--title {
  font-size: 1.125rem;
}
.CreateOrEditGoalFields--individuals-select {
  margin-top: -20px;
}
.CreateOrEditGoalFields--key-results .ListEditor--item {
  margin: 14px 0;
}
.CreateOrEditGoalFields--key-results-item input {
  font-size: 1.125rem !important;
}
.CreateOrEditGoalFields--key-results-item-adder {
  height: 52px;
  border-radius: 3px;
  border: 1px dashed #e1e2ea;
  color: #0168b3;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  transition: box-shadow 0.2s ease-out;
  box-shadow: none;
}
.CreateOrEditGoalFields--key-results-item-adder:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
}
.CreateOrEditGoalModal--with-subheading {
  text-align: left;
  margin: 0px;
}
.CreateOrEditGoalFields--subheading {
  text-align: left;
  margin: 5px 0 0 0;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.CreateOrEditGoalFields--buttons {
  display: flex;
  align-items: center;
  padding: 35px 0 50px 0;
}
.CreateOrEditGoalFields--buttons-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.CreateOrEditGoalFields--buttons-delete-container {
  display: flex;
  align-items: center;
}
