/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.InputField {
  position: relative;
}
.InputField .InputClearButton {
  position: absolute;
  right: 0;
  top: 0;
}
.InputField textarea {
  color: #35374a;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  height: 100%;
  width: 100%;
  margin: 0 0 15px 0;
  box-shadow: none;
  box-sizing: content-box;
  overflow-y: hidden;
  padding: 1.6rem 0;
  resize: none;
  min-height: 3rem;
  transition: 0.25s;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.InputField textarea:disabled {
  opacity: 0.5;
}
.InputField--borderless input,
.InputField--bordered input,
.InputField--borderless textarea,
.InputField--bordered textarea {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background-color: none;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  box-shadow: none;
  position: relative;
  border: none;
}
.InputField--borderless input.valid,
.InputField--bordered input.valid,
.InputField--borderless textarea.valid,
.InputField--bordered textarea.valid,
.InputField--borderless input.invalid,
.InputField--bordered input.invalid,
.InputField--borderless textarea.invalid,
.InputField--bordered textarea.invalid {
  border: none;
  box-shadow: none;
}
.InputField--borderless input:focus,
.InputField--bordered input:focus,
.InputField--borderless textarea:focus,
.InputField--bordered textarea:focus {
  border: none;
  box-shadow: none;
}
.InputField--borderless input,
.InputField--bordered input {
  padding: 11.5px 5px;
}
.InputField--borderless textarea,
.InputField--bordered textarea {
  padding: 15px;
}
.InputField--clearable input::-ms-clear {
  width: 0;
  height: 0;
}
.InputField--clearable.InputField--bordered input,
.InputField--clearable.InputField--bordered textarea {
  padding-right: 40px;
}
.InputField--borderless input,
.InputField--borderless textarea,
.InputField--borderless .InputClearButton {
  height: 54px;
  min-height: 54px;
}
.InputField--bordered {
  overflow: hidden;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #e1e2ea;
}
.InputField--bordered.InputField--focused {
  z-index: 10;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
}
.InputField--bordered input,
.InputField--bordered textarea,
.InputField--bordered .InputClearButton {
  height: 52px;
  min-height: 52px;
}
.InputField--before-content {
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.InputField--unstyled input,
.InputField--unstyled textarea {
  background: none;
  height: unset;
  min-height: unset;
  padding: 0;
  margin-left: -5px;
}
