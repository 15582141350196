/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/** THIS IS AN AUTOGENERATED FILE **/
.LongAnswer {
  margin: 50px 0;
}
.LongAnswer .TextEditor,
.LongAnswer .TextEditor .DraftEditor-root,
.LongAnswer .TextEditor .public-DraftEditor-content,
.LongAnswer .TextEditor textarea {
  min-height: 150px;
}
.LongAnswer--question {
  align-items: baseline;
}
.LongAnswer--question-shared {
  margin-bottom: 0.75rem;
  position: relative;
  display: inline-block;
}
.LongAnswer--question-number {
  margin-right: 5px;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.LongAnswer--read-only-text {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  text-align: left;
}
.LongAnswer--skill-name {
  margin-bottom: 10px;
  white-space: pre-wrap;
  text-align: left;
}
.LongAnswer--skill-name-title {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.LongAnswer--skill-name-optional {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: rgba(53, 55, 74, 0.8);
  padding-left: 0.375rem;
}
.LongAnswer--skill-name-description {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  color: rgba(53, 55, 74, 0.8);
}
.LongAnswer--asterisk {
  margin-left: 5px;
  font-weight: 700;
}
