.AddAnswerChoiceButton {
  margin: 0;
  border-top: 1px solid #e1e2ea;
  padding: 0;
  height: 45px;
  width: 100%;
  font: inherit;
}
.AddAnswerChoiceButton.btn-flat {
  border-radius: 0;
}
