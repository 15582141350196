/** THIS IS AN AUTOGENERATED FILE **/
.PastFeedbackItem {
  margin-bottom: 20px;
  text-align: left;
  word-wrap: break-word;
  border-bottom: 1px solid #ebebed;
}
.PastFeedbackItem > p {
  margin: 14px 0 20px;
}
.PastFeedbackItem--copy-button {
  margin-top: -5px;
  margin-left: 10px;
}
.PastFeedbackItem--avatar-container {
  margin-right: 15px;
}
.PastFeedbackItem--avatar-container .Avatar {
  width: 43px;
  height: 43px;
}
.PastFeedbackItem--no-reviewer-avatar {
  color: rgba(53, 55, 74, 0.8);
  font-size: 40px;
}
.PastFeedbackItem--question-answer,
.PastFeedbackItem--notes {
  white-space: pre-wrap;
  margin-bottom: 20px;
}
.PastFeedbackItem--question-answer p,
.PastFeedbackItem--notes p,
p.PastFeedbackItem--question-answer,
p.PastFeedbackItem--notes {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  color: #35374a;
}
.PastFeedbackItem--question {
  font-weight: 600;
  margin: 10px 0;
}
.PastFeedbackItem--question-with-breaks .MarkdownText--inline {
  white-space: pre-wrap;
}
.PastFeedbackItem--question-with-breaks .MarkdownText--inline-paragraph {
  display: block;
  margin-bottom: 5px;
}
.PastFeedbackItem--content {
  min-width: 0;
}
.PastFeedbackItem--content .CompletedMRFSurveyResponse--item {
  width: 100%;
}
.PastFeedbackItem--content .SurveyQuestion--rating .MultipleChoice--button {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.PastFeedbackItem--answer {
  margin: 0;
}
.PastFeedbackItem--shared-description {
  margin-bottom: 20px;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.PastFeedbackItem--shared-description span {
  font-style: italic;
}
.PastFeedbackItem--sharedMRF-description {
  margin-bottom: 20px;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.PastFeedbackItem--sharedMRF-description span {
  font-style: italic;
}
.PastFeedbackItem--team-name {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  margin-bottom: 20px;
}
.PastFeedbackItem--shareButton-container {
  position: relative;
}
.PastFeedbackItem--deleteButton-container {
  position: relative;
}
