/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
/** THIS IS AN AUTOGENERATED FILE **/
.CompletedPeerFeedbackModal .ConversationModalWrapper,
.CompletedPeerFeedbackModal .LockedFeedbackWrapper,
.CompletedPeerFeedbackModal .ResponsiveWrapper,
.CompletedPeerFeedbackModal .ResponsiveWrapper--container {
  height: 100%;
}
.CompletedPeerFeedbackModal .ResponsiveWrapper--container {
  padding-bottom: 0;
}
.CompletedPeerFeedbackModal .Modal--content {
  margin-top: 0;
}
.CompletedPeerFeedbackModal .page-header {
  margin-top: 70px;
}
.CompletedPeerFeedbackModal .ConversationMessage {
  background-color: #fff;
}
.CompletedPeerFeedbackModal .QuestionsAndAnswersComment--question {
  white-space: pre-wrap;
}
.CompletedPeerFeedbackModal .QuestionsAndAnswersComment--question .MarkdownText--inline-paragraph {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.CompletedPeerFeedbackModal--feedback-container .CompletedMRFSurveyResponse {
  padding: 20px;
}
.CompletedPeerFeedbackModal--feedback-container .PastFeedbackItem {
  border: 0;
}
.CompletedPeerFeedbackModal--feedback-container .PastFeedbackItem--avatar-container {
  margin-left: 6px;
}
.CompletedPeerFeedbackModal--feedback-container .PastFeedbackItem--avatar-container .Avatar {
  width: 60px;
  height: 60px;
}
