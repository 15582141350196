@import "materialize.css";
/* Material UI scaffolding, including normalize.css */
/* Material UI component styling */
/* Animate.css animations */
/** THIS IS AN AUTOGENERATED FILE **/
/*******************************
          Flex Layout
*******************************/
.layout.horizontal,
.layout.vertical {
  /* autoprefixer: off */
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-basis: auto;
}
.layout.horizontal.inline,
.layout.vertical.inline {
  /* autoprefixer: off */
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.layout.horizontal {
  /* autoprefixer: off */
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.layout.horizontal.reverse {
  /* autoprefixer: off */
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.layout.vertical {
  /* autoprefixer: off */
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.layout.vertical.reverse {
  /* autoprefixer: off */
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.layout.wrap {
  /* autoprefixer: off */
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.layout.wrap-reverse {
  /* autoprefixer: off */
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.flex {
  /* autoprefixer: off */
  -ms-flex: 1 1 0.000000001px;
  -webkit-flex: 1;
  flex: 1 1;
  -webkit-flex-basis: 0.000000001px;
  flex-basis: 0.000000001px;
}
.vertical.layout .flex.auto-vertical {
  /* autoprefixer: off */
  -ms-flex: 1 1 auto;
  -webkit-flex-basis: auto;
  flex-basis: auto;
}
.flex.auto {
  /* autoprefixer: off */
  -ms-flex: 1 1 auto;
  -webkit-flex-basis: auto;
  flex-basis: auto;
}
.flex.none {
  /* autoprefixer: off */
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.flex.one {
  /* autoprefixer: off */
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1 1;
}
.flex.two {
  /* autoprefixer: off */
  -ms-flex: 2;
  -webkit-flex: 2;
  flex: 2 1;
}
.flex.three {
  /* autoprefixer: off */
  -ms-flex: 3;
  -webkit-flex: 3;
  flex: 3 1;
}
.flex.four {
  /* autoprefixer: off */
  -ms-flex: 4;
  -webkit-flex: 4;
  flex: 4 1;
}
.flex.five {
  /* autoprefixer: off */
  -ms-flex: 5;
  -webkit-flex: 5;
  flex: 5 1;
}
.flex.six {
  /* autoprefixer: off */
  -ms-flex: 6;
  -webkit-flex: 6;
  flex: 6 1;
}
.flex.seven {
  /* autoprefixer: off */
  -ms-flex: 7;
  -webkit-flex: 7;
  flex: 7 1;
}
.flex.eight {
  /* autoprefixer: off */
  -ms-flex: 8;
  -webkit-flex: 8;
  flex: 8 1;
}
.flex.nine {
  /* autoprefixer: off */
  -ms-flex: 9;
  -webkit-flex: 9;
  flex: 9 1;
}
.flex.ten {
  /* autoprefixer: off */
  -ms-flex: 10;
  -webkit-flex: 10;
  flex: 10 1;
}
.flex.eleven {
  /* autoprefixer: off */
  -ms-flex: 11;
  -webkit-flex: 11;
  flex: 11 1;
}
.flex.twelve {
  /* autoprefixer: off */
  -ms-flex: 12;
  -webkit-flex: 12;
  flex: 12 1;
}
/* alignment in cross axis */
.layout.start {
  /* autoprefixer: off */
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.layout.center,
.layout.center-center {
  /* autoprefixer: off */
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.layout.end {
  /* autoprefixer: off */
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
/* alignment in main axis */
.layout.start-justified {
  /* autoprefixer: off */
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.layout.center-justified,
.layout.center-center {
  /* autoprefixer: off */
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.layout.end-justified {
  /* autoprefixer: off */
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.layout.around-justified {
  /* autoprefixer: off */
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.layout.justified {
  /* autoprefixer: off */
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
/* self alignment */
.self-start {
  /* autoprefixer: off */
  -ms-align-self: flex-start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}
.self-center {
  /* autoprefixer: off */
  -ms-align-self: center;
  -webkit-align-self: center;
  align-self: center;
}
.self-end {
  /* autoprefixer: off */
  -ms-align-self: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
.self-stretch {
  /* autoprefixer: off */
  -ms-align-self: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}
/*******************************
          Other Layout
*******************************/
.block {
  /* autoprefixer: off */
  display: block;
}
/* ie support for hidden */
.hidden {
  /* autoprefixer: off */
  display: none !important;
}
.relative {
  /* autoprefixer: off */
  position: relative;
}
.fit {
  /* autoprefixer: off */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
body.fullbleed {
  /* autoprefixer: off */
  margin: 0;
  height: 100vh;
}
/*******************************
            Other
*******************************/
.segment,
segment {
  /* autoprefixer: off */
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1em 0.5em;
  padding: 1em;
  background-color: white;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 5px 5px;
}
/**
 * Polymer's Iron Flex Layout and Materialize both use the class 'center'. In the former, it
 * means `align-items: center`, in the latter, `text-align: center`. This is an unambiguous
 * alternative for the flex case.
 */
.center-items {
  align-items: center;
}
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
input.flatpickr-input {
  color: #35374a;
  height: 54px;
  min-height: 54px;
  background-color: white;
  width: 100%;
  margin: 0;
  padding: 11.5px 15px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #e1e2ea;
  box-shadow: none;
}
input.flatpickr-input.valid,
input.flatpickr-input.invalid {
  border: 1px solid #e1e2ea;
  box-shadow: none;
}
input.flatpickr-input:focus {
  border: 1px solid #e1e2ea;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
}
input.flatpickr-input:disabled {
  cursor: default;
  opacity: 0.25;
}
.flatpickr-current-month {
  color: #35374a !important;
  font-family: "Greycliff CF", "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: -0.03em !important;
  font-size: 1.375rem !important;
  line-height: 1.09090909 !important;
  position: relative !important;
  -webkit-font-smoothing: antialiased !important;
  padding: 0 !important;
  left: 0 !important;
}
.flatpickr-current-month input.cur-year {
  font-weight: 400 !important;
}
.flatpickr-current-month span.cur-month {
  font-weight: 400 !important;
  margin: 0 !important;
}
.flatpickr-month {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: normal !important;
  font-size: 1.125rem !important;
  line-height: 1.33333333 !important;
  position: relative !important;
  -webkit-font-smoothing: antialiased !important;
  background-color: #f3f3f4 !important;
  height: 30px !important;
}
.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  fill: #21a38b !important;
}
.flatpickr-prev-month svg:hover,
.flatpickr-next-month svg:hover {
  opacity: 0.5;
}
.flatpickr-day.today {
  background-color: #f3f3f4 !important;
  color: teal !important;
}
.numInputWrapper input {
  width: auto !important;
}
.flatpickr-weekdays {
  background-color: #f3f3f4 !important;
  height: 35px !important;
}
.flatpickr-weekdaycontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
span.flatpickr-weekday {
  background-color: #f3f3f4 !important;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0.04166667em !important;
  font-size: 0.75rem !important;
  line-height: 2 !important;
  position: relative !important;
  -webkit-font-smoothing: antialiased !important;
  opacity: 0.5;
  text-transform: uppercase !important;
  color: #35374a !important;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 315px;
  border-left: 1px solid #e1e2ea;
  border-right: 1px solid #e1e2ea;
}
.flatpickr-calendar {
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border: 1px solid #e1e2ea;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.inRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: teal !important;
  box-shadow: none !important;
  color: #fff !important;
  border-color: teal !important;
}
.flatpickr-time input {
  color: #35374a;
}
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e9ebec;
}
/*
  flatpickr has the following styles

  .flatpickr-current-month .numInputWrapper {
    width: 6ch;
    width: 7ch\0;
  }

  Not sure what is the point of width: 7ch\0 but it's invalid.
  Unfortunately UglifyJsPlugin strips out width: 6ch, so we manually
  declare it here.
*/
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
}
.rc-tooltip {
  opacity: 1;
  color: #35374a;
  padding: 0;
  border: 1px solid #e1e2ea;
  border-radius: 3px;
  background-color: transparent;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
.rc-tooltip-content {
  border-radius: 3px;
}
.rc-tooltip-inner {
  padding: 0;
  border: none;
  border-radius: 3px;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-bottom-color: #e1e2ea;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: #e1e2ea;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  border-left-color: #e1e2ea;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  border-right-color: #e1e2ea;
}
/**
 * any css styles that directly modify material-icons 
 */
.material-icons.md-12 {
  font-size: 12px;
}
.material-icons.icon-flip-horizontal {
  transform: scale(-1, 1);
}
.icon-unread:after {
  content: "•";
  color: #fa7558;
  font-size: 24px;
}
.icon-incomplete:after {
  content: "○";
  color: #fa7558;
  font-style: normal;
  font-size: 21px;
}
.icon-done:after {
  content: "○";
  visibility: hidden;
  font-size: 24px;
}
.icon-share {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  height: 20px;
  width: 18px;
  background-size: cover;
  background-image: url("static/images-2017/icons/icon-share.png");
}
.icon-star-yes {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-image: url("static/images-2017/icons/icon-star-yes-2.png");
  background-size: cover;
}
.icon-star-no {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-image: url("static/images-2017/icons/icon-star-no-2.png");
  background-size: cover;
}
.icon-envelope {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 28px;
  background-image: url("static/images-2017/icons/icon-envelope.png");
}
.icon-heart {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  background-image: url("static/images-2017/icons/icon-heart.png");
  background-size: cover;
}
.icon-heart-filled {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  background-image: url("static/images-2017/icons/icon-heart-filled.png");
  background-size: cover;
}
/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: #ffffff;
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
/**
* Campification
*/
body {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.btn-flat.btn-bordered {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
/**
 * In-between a heading and body text - a little larger than body text but
 * lighter than a header.
 *
 * Example:
 *
 * 		<h4>Company Settings</h4>
 * 		<p class="subheading">
 * 			Adjust your company's avatar, title, etc.
 * 		</p>
 */
.subheading {
  font-size: 1.125rem;
  line-height: 1.5;
  color: rgba(53, 55, 74, 0.8);
  margin: 0.4rem 0 1rem;
  text-align: center;
}
/**
 * Chops off the bottom margin of a heading to make it jive better with a
 * `.subheading`.
 *
 * Example:
 *
 * 		<h4 class="with-subheading">Company Settings</h4>
 * 		<p class="subheading">
 * 			Adjust your company's avatar, title, etc.
 * 		</p>
 */
.with-subheading {
  margin-top: 0;
  margin-bottom: 10px;
  margin-bottom: 0;
}
/**
 * Shaded lighter than regular text.
 *
 * Example:
 *
 * 		<p class="secondary-text">
 * 			This may or may not interest you
 * 		</p>
 */
.secondary-text {
  color: rgba(53, 55, 74, 0.8);
}
.page-header {
  margin-top: 0;
  margin-bottom: 10px;
}
/* Structure
====================================== */
html {
  height: 100%;
  max-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
}
body {
  height: 100%;
  max-height: 100%;
  line-height: 19px;
  color: #35374a;
  background: #f6f6f6;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings: normal, "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
}
@media (max-width: 500px) {
  #app {
    overflow-x: hidden;
  }
}
hr {
  border: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px rgba(255, 255, 255, 0.75);
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: rgba(53, 55, 74, 0.8);
  opacity: 1;
}
input::placeholder,
textarea::placeholder,
input::-moz-focus-inner,
textarea::-moz-focus-inner {
  color: rgba(53, 55, 74, 0.8);
  opacity: 1;
}
.labeled-rule {
  position: relative;
  height: 2.5em;
  margin: 0 auto;
}
.labeled-rule p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  background-color: #eeeeee;
  padding: 0px 25px;
}
:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}
/* Animations
====================================== */
.animated {
  transition: all 0.5s ease-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pulse-size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse-size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes raise {
  0% {
    transform: scaleY(0.01);
  }
  75% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes raise {
  0% {
    transform: scaleY(0.01);
  }
  75% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
/* Utilities/Helpers/Mixins
====================================== */
.hidden {
  display: none !important;
}
@media (min-width: 500px) {
  .show-on-mobile-only {
    /* autoprefixer: off */
    display: none !important;
  }
}
@media (max-width: 500px) {
  .hide-on-mobile-only {
    /* autoprefixer: off */
    display: none !important;
  }
}
@media (min-height: 700px) {
  .show-on-short-only {
    /* autoprefixer: off */
    display: none !important;
  }
}
@media (max-height: 700px) {
  .hide-on-short-only {
    /* autoprefixer: off */
    display: none !important;
  }
}
@media (max-width: 900px) {
  .hide-on-small-only {
    /* autoprefixer: off */
    display: none !important;
  }
}
@media (min-width: 900px) {
  .show-on-small-only {
    /* autoprefixer: off */
    display: none !important;
  }
}
@media (max-width: 500px) {
  .no-padding-on-mobile {
    padding: 0px !important;
  }
}
@media (max-width: 500px) {
  .no-margin-on-mobile {
    margin: 0px !important;
  }
}
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}
.light-weight {
  font-weight: lighter;
}
.normal-weight {
  font-weight: normal;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.clear-both {
  clear: both !important;
}
.full-width {
  width: 100% !important;
}
.full-height {
  height: 100% !important;
}
.flex {
  flex: 1 1 auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.pointer {
  cursor: pointer !important;
}
.faded {
  opacity: 0.8;
}
.blurred {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.error {
  color: #e22b3e;
}
.scroll {
  overflow: auto;
}
.invisible {
  opacity: 0;
  visibility: hidden;
}
.collapsed {
  height: 0px !important;
  margin: 0px !important;
  overflow: hidden;
  visibility: hidden;
}
