/** THIS IS AN AUTOGENERATED FILE **/
.EditableAnswerChoice {
  border-top: 1px solid #e1e2ea;
  background-color: #fff;
}
.EditableAnswerChoice input {
  font-size: inherit;
}
.EditableAnswerChoice .InputClearButton {
  height: 54px;
}
.EditableAnswerChoice--content {
  background-color: #fff;
}
.EditableAnswerChoice--value-indicator {
  width: 50px;
}
.EditableAnswerChoice--title-field input {
  color: #35374a;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.EditableAnswerChoice--title-field input::-webkit-input-placeholder {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.EditableAnswerChoice--title-field input::-moz-placeholder {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.EditableAnswerChoice--title-field input:-ms-input-placeholder {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.EditableAnswerChoice--title-field input::-ms-input-placeholder {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.EditableAnswerChoice--title-field input::placeholder {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.EditableAnswerChoice--with-description .EditableAnswerChoice--title-field input {
  padding-bottom: 1.5px;
  height: 44px;
  min-height: 44px;
}
.EditableAnswerChoice--description-field input {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  opacity: 0.7;
  padding-top: 1.5px;
  padding-bottom: 11.5px;
  height: 41px;
  min-height: 41px;
}
