@media (max-width: 500px) {
  .BaseViewGoalModal {
    padding: 45px 0 0;
  }
}
.BaseViewGoalModal hr {
  margin: 20px 0;
}
.BaseViewGoalModal .GoalStatus {
  font-family: "Greycliff CF", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: -0.03em;
  font-size: 1.375rem;
  line-height: 1.09090909;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 300px) {
  .BaseViewGoalModal .GoalStatus {
    font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: normal;
    font-size: 1.125rem;
    line-height: 1.33333333;
    position: relative;
    -webkit-font-smoothing: antialiased;
    padding-top: 5px;
  }
}
.BaseViewGoalModal .GoalKeyResultList {
  padding-top: 25px;
  padding-bottom: 25px;
}
.BaseViewGoalModal .GoalAlignmentList {
  padding-top: 25px;
}
.BaseViewGoalModal--goal-header {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-direction: row;
}
.BaseViewGoalModal--goal-header > * {
  margin-left: 10px;
  margin-right: 10px;
}
@media (max-width: 300px) {
  .BaseViewGoalModal--goal-header {
    flex-direction: column;
    font-family: "Greycliff CF", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: -0.03em;
    font-size: 1.375rem;
    line-height: 1.09090909;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }
}
.BaseViewGoalModal--goal-header .GoalStatus {
  display: none;
}
.BaseViewGoalModal .MarkdownText--content-block {
  font-size: 16px;
  color: #35374a;
  line-height: normal;
}
.BaseViewGoalModal--overall-completion-slider,
.BaseViewGoalModal--overall-completion-bar {
  min-width: 100px;
}
.BaseViewGoalModal .Conversation {
  max-height: 350px;
}
.BaseViewGoalModal .CreateOrEditGoalFields--buttons .Button {
  height: 35px !important;
  line-height: 35px !important;
}
.BaseViewGoalModal--activity .CollapsibleSection--title,
.BaseViewGoalModal--incoming-goals .CollapsibleSection--title {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
}
.BaseViewGoalModal .GoalVisibilityIndicator {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding-top: 10px;
}
.BaseViewGoalModal--section-header {
  font-size: 18px;
  margin-bottom: 10px;
}
.BaseViewGoalModal--goal-content,
.BaseViewGoalModal--section-header,
.BaseViewGoalModal--audit-item {
  text-align: left;
}
.BaseViewGoalModal--priority {
  padding-top: 35px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.04166667em;
  font-size: 0.75rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .BaseViewGoalModal--priority {
    padding-top: 10px;
  }
}
.BaseViewGoalModal--goal-content {
  margin-bottom: 10px;
}
.BaseViewGoalModal--name {
  font-family: "Greycliff CF", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: -0.03em;
  font-size: 1.375rem;
  line-height: 1.09090909;
  position: relative;
  -webkit-font-smoothing: antialiased;
  min-width: 250px;
}
.BaseViewGoalModal--description-title {
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 5px;
}
.BaseViewGoalModal--description-text {
  padding-top: 10px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.BaseViewGoalModal--status-select {
  width: 230px;
  padding-top: 10px;
}
.BaseViewGoalModal--conversations-wrapper {
  background-color: white;
  border-top: 1px solid #e1e2ea;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.BaseViewGoalModal--conversations-wrapper .GoalConversationSection {
  width: 100%;
}
.BaseViewGoalModal--incoming-goals-table {
  margin-right: -21px;
  margin-left: -21px;
}
.BaseViewGoalModal--incoming-goals-table .GoalsTable--search-results {
  margin-bottom: 0;
}
.BaseViewGoalModal--incoming-goals-table .GoalsTable--search-results .GoalsTable--owner-cell {
  max-width: 180px;
}
.BaseViewGoalModal--audit-wrapper {
  font-size: 14px;
}
.BaseViewGoalModal--audit-avatar .Avatar {
  width: 45px !important;
  height: 45px !important;
  margin-right: 10px;
}
.BaseViewGoalModal--audit-item {
  margin-bottom: 1.5em;
}
.BaseViewGoalModal--audit-dateline {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.BaseViewGoalModal--list-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid #e1e2ea;
  border-right: 1px solid #e1e2ea;
  border-bottom: 1px solid #e1e2ea;
  border-radius: 3px;
}
.BaseViewGoalModal--subheader {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding-top: 5px;
  margin: 0;
}
@media (max-width: 300px) {
  .BaseViewGoalModal--subheader {
    font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 300;
    letter-spacing: normal;
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    -webkit-font-smoothing: antialiased;
    padding-top: 0px;
    text-align: left;
  }
}
.BaseViewGoalModal--header {
  text-align: left;
  margin: 0;
}
@media (max-width: 300px) {
  .BaseViewGoalModal--header {
    font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: normal;
    font-size: 1.125rem;
    line-height: 1.33333333;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }
}
.BaseViewGoalModal--incoming-goals {
  background-color: white;
  display: flex;
  border-top: 1px solid #e1e2ea;
  padding: 15px 20px;
  flex-direction: column;
}
.BaseViewGoalModal--incoming-goals .CollapsibleSection--title {
  width: 100%;
}
.BaseViewGoalModal--edit-buton-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.BaseViewGoalModal--activity-container {
  background-color: white;
  border-top: 1px solid #e1e2ea;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.BaseViewGoalModal--audit-user-line {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.BaseViewGoalModal--audit-change {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.BaseViewGoalModal--overall-container {
  display: flex;
  align-items: center;
  justify-items: end;
}
