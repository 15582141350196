.CompletedMRFSurveyResponse {
  background-color: transparent;
  border-radius: 8px 8px 8px 8px;
}
.CompletedMRFSurveyResponse--title {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.CompletedMRFSurveyResponse--date {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.CompletedMRFSurveyResponse--title {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-bottom: -10px;
}
.CompletedMRFSurveyResponse--item {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.CompletedMRFSurveyResponse--description {
  margin-bottom: 20px;
}
.CompletedMRFSurveyResponse .CopyToClipboardButton {
  margin-left: 10px;
}
.CompletedMRFSurveyResponse .SurveyQuestion {
  margin: 10px 0 24px;
}
.CompletedMRFSurveyResponse .MultipleChoice--button {
  opacity: 1 !important;
}
.CompletedMRFSurveyResponse--copy-button-container {
  margin-top: -18px;
}
