/** THIS IS AN AUTOGENERATED FILE **/
.RepositionableItemNumber {
  width: 20px;
  margin: -6px 10px 0px -6px;
  height: 70px;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.RepositionableItemNumber--move {
  font-size: 28px;
  color: rgba(53, 55, 74, 0.45);
  cursor: pointer;
  transition: opacity 0.2s;
}
.RepositionableItemNumber--move:hover {
  color: #35374a;
}
.RepositionableItemNumber--move-hidden {
  visibility: hidden;
}
