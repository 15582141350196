/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.EmptyState {
  width: 100%;
  background-color: #ececef;
  border: 1px solid #d7d7db;
  padding: 31px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
@media (max-width: 300px) {
  .EmptyState {
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .EmptyState {
    flex-direction: column;
  }
}
.EmptyState--img {
  max-width: 100%;
  max-height: 300px;
}
@media (max-width: 500px) {
  .EmptyState--img {
    max-height: 170px;
  }
}
.EmptyState--image-container {
  min-width: 300px;
  flex: 0 1 40%;
}
@media (max-width: 500px) {
  .EmptyState--image-container {
    min-width: 0px;
  }
}
.EmptyState--body {
  color: rgba(53, 55, 74, 0.8);
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-top: 0;
  white-space: pre-wrap;
  flex-grow: 1;
}
.EmptyState--body a {
  color: #0168b3;
}
.EmptyState--text-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  padding: 0 30px;
}
@media (max-width: 500px) {
  .EmptyState--text-container {
    padding: 0px;
  }
}
.EmptyState--text-container h2 {
  text-align: left;
  margin-bottom: 10px;
}
.EmptyState--text-container p {
  text-align: left;
  color: #35374a;
}
