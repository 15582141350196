/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
@-webkit-keyframes LockedFeedbackWrapper--left-panel-animation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-90deg);
  }
}
@keyframes LockedFeedbackWrapper--left-panel-animation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-90deg);
  }
}
@-webkit-keyframes LockedFeedbackWrapper--right-panel-animation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(90deg);
  }
}
@keyframes LockedFeedbackWrapper--right-panel-animation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(90deg);
  }
}
@-webkit-keyframes LockedFeedbackWrapper--sticker-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes LockedFeedbackWrapper--sticker-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.LockedFeedbackWrapper--left-panel,
.LockedFeedbackWrapper--right-panel {
  padding: 0;
  background: url("../../static/images-2017/leaf-wrapping-paper-right.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: box-shadow 0.3s;
  border: none;
  height: 100%;
  width: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.LockedFeedbackWrapper--left-panel {
  border-radius: 3px 0 0 3px;
  background: url("../../static/images-2017/leaf-wrapping-paper-left.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.LockedFeedbackWrapper--right-panel {
  border-radius: 0 3px 3px 0;
}
.LockedFeedbackWrapper--locked {
  cursor: pointer;
}
.LockedFeedbackWrapper--locked:hover .LockedFeedbackWrapper--left-panel,
.LockedFeedbackWrapper--locked:hover .LockedFeedbackWrapper--right-panel,
.LockedFeedbackWrapper--locked:hover .LockedFeedbackWrapper--sticker {
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
}
.LockedFeedbackWrapper--panel-wrapper {
  height: 80%;
  width: 100%;
  perspective: 1000px;
}
.LockedFeedbackWrapper--sticker {
  transition: box-shadow 0.3s;
  position: absolute;
  margin-top: -72.5px;
  left: 50%;
  margin-left: -67.5px;
  width: 135px;
  height: 65px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #e1e2ea;
}
.LockedFeedbackWrapper--sticker > * {
  padding: 5px 12px;
}
.LockedFeedbackWrapper--left-panel-animation,
.LockedFeedbackWrapper--right-panel-animation,
.LockedFeedbackWrapper--sticker-animation {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.LockedFeedbackWrapper--left-panel-animation {
  transform-origin: 0;
  -webkit-animation-name: LockedFeedbackWrapper--left-panel-animation;
          animation-name: LockedFeedbackWrapper--left-panel-animation;
}
.LockedFeedbackWrapper--right-panel-animation {
  transform-origin: 100%;
  -webkit-animation-name: LockedFeedbackWrapper--right-panel-animation;
          animation-name: LockedFeedbackWrapper--right-panel-animation;
}
.LockedFeedbackWrapper--sticker-animation {
  -webkit-animation-name: LockedFeedbackWrapper--sticker-animation;
          animation-name: LockedFeedbackWrapper--sticker-animation;
}
.LockedFeedbackWrapper--buy-failed-content {
  text-align: center;
}
.LockedFeedbackWrapper--profile {
  font-size: 25px;
  color: rgba(53, 55, 74, 0.8);
}
.LockedFeedbackWrapper--sticker-text {
  flex-direction: column;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  display: flex;
  border-radius: 3px;
}
.LockedFeedbackWrapper--panel-wrapper {
  display: flex;
  align-items: center;
  max-width: 800px;
  height: 300px;
}
.LockedFeedbackWrapper--panel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
