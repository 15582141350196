/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/** THIS IS AN AUTOGENERATED FILE **/
.ProgressBar {
  position: relative;
  border-radius: 3px;
  background-color: #ececef;
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.ProgressBar--completion-wrapper {
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.ProgressBar--completion {
  height: 100%;
  color: white;
  text-align: left;
  background-color: #6b6e94;
  display: flex;
  align-items: center;
}
.ProgressBar--completion-empty {
  color: #35374a;
}
.ProgressBar--completion-fill {
  color: #35374a;
}
.ProgressBar--completion-full {
  flex-direction: row-reverse;
}
.ProgressBar--text {
  margin: 0 5px;
}
