@media (max-width: 1150px) {
  .EvaluationsTable .ProfilePic {
    display: none;
  }
}
.EvaluationsTable--employee-cell {
  min-width: 200px;
}
.EvaluationsTable--flag-cell.Table--cell,
.EvaluationsTable--share-cell.Table--cell {
  min-width: 65px;
  max-width: 65px;
  width: 65px;
  color: rgba(53, 55, 74, 0.8);
}
.EvaluationsTable--flag-cell.Table--cell.Table--body-cell,
.EvaluationsTable--share-cell.Table--cell.Table--body-cell {
  box-shadow: 0 0 0 0 !important;
}
.EvaluationsTable--share-cell-inactive {
  opacity: 0.25;
}
.EvaluationsTable--flag-cell-faded i {
  opacity: 0.5;
}
.EvaluationsTable--incomplete-bucket {
  color: rgba(53, 55, 74, 0.8);
}
.EvaluationsTable--editable-cell {
  min-width: 150px;
}
.EvaluationsTable--profile-cell {
  color: rgba(53, 55, 74, 0.8);
  width: 1%;
}
.EvaluationsTable--profile-cell i {
  vertical-align: middle;
}
.EvaluationsTable--cycle-date {
  margin-bottom: 5px;
  color: #35374a;
  opacity: 0.7;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.EvaluationsTable--dropdown-field .Select-control {
  background-color: transparent;
}
.EvaluationsTable--cycle-name {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
}
.EvaluationsTable--collaborator-tooltip {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding: 16px;
  max-width: 200px;
}
.EvaluationsTable .Table--row:not(:hover) .Typeahead--zugata-style .Select-control {
  border-color: transparent;
}
.EvaluationsTable--survey-print-icon,
.EvaluationsTable--collaborator-icon {
  opacity: 0.5;
}
.EvaluationsTable--survey-print-icon i,
.EvaluationsTable--collaborator-icon i {
  color: #35374a;
}
.EvaluationsTable .Table--body-row:hover .EvaluationsTable--survey-print-icon,
.EvaluationsTable .Table--body-row:hover .EvaluationsTable--collaborator-icon {
  opacity: 1;
}
.EvaluationsTable .Table--body-row:hover .mdi-navigation-more-horiz {
  border: 1px solid rgba(53, 55, 74, 0.45);
  border-radius: 3px;
}
.EvaluationsTable--menuItems {
  padding: 10px !important;
}
.EvaluationsTable--collaborator-icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
}
