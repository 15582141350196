/** THIS IS AN AUTOGENERATED FILE **/
.ProfileSelfReflectionSurveyResponseList--cycle-name {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-bottom: 15px;
}
.ProfileSelfReflectionSurveyResponseList .ProfileItemVisibilityIndicator {
  margin-left: 10px;
}
.ProfileSelfReflectionSurveyResponseList .ProfilePic {
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
}
.ProfileSelfReflectionSurveyResponseList .ProfilePic .Avatar {
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
}
.ProfileSelfReflectionSurveyResponseList .ProfilePic--deactivated {
  font-size: 45px;
}
.ProfileSelfReflectionSurveyResponseList--divider {
  margin: 40px 0 20px;
}
