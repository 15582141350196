.SharedFeedbackModal--share-divider,
.container .SharedFeedbackModal--share-divider {
  margin-left: -5px;
  margin-right: -5px;
}
.SharedFeedbackModal--nested-review {
  margin-bottom: 0;
}
.SharedFeedbackModal--nested-review .MessageGroup--author {
  margin-bottom: -5px;
}
.SharedFeedbackModal--nested-review .ConversationMessage--timestamp {
  margin-bottom: 4px;
}
.SharedFeedbackModal--nested-review .ConversationMessage {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;
}
.SharedFeedbackModal--nested-review .ProfilePic img {
  max-width: 45px;
  max-height: 45px;
  min-width: 45px;
  min-height: 45px;
}
