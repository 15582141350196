/** THIS IS AN AUTOGENERATED FILE **/
.ModalGoalProgressBar .ProgressBar {
  width: 100%;
}
.ModalGoalProgressBar .ProgressBar--text {
  font-size: 12px;
  margin-left: 12px;
}
.ModalGoalProgressBar--blocked-icon {
  font-size: 14px;
  position: absolute;
  right: 8px;
  color: #e22b3e;
  text-shadow: 0 0 2px #e1e2ea;
}
.ModalGoalProgressBar--blocked-icon-inverted {
  color: white;
  text-shadow: none;
}
.GoalProgressBar,
.ModalGoalProgressBar {
  width: 100%;
  position: relative;
  padding: 0 12px 12px 12px;
}
.GoalProgressBar--created,
.ModalGoalProgressBar--created {
  background-color: #6b6e94;
}
.GoalProgressBar--ongoing,
.ModalGoalProgressBar--ongoing {
  background-color: #6b6e94;
}
.GoalProgressBar--blocked,
.ModalGoalProgressBar--blocked {
  background-color: #6b6e94;
}
.GoalProgressBar--accomplished,
.ModalGoalProgressBar--accomplished {
  background-color: #6b6e94;
}
.GoalProgressBar .ProgressBar,
.ModalGoalProgressBar .ProgressBar {
  width: 100%;
  height: 24px;
}
.GoalProgressBar .ProgressBar--text,
.ModalGoalProgressBar .ProgressBar--text {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.GoalProgressBar .GoalStatus,
.ModalGoalProgressBar .GoalStatus {
  text-transform: capitalize;
  margin-top: 8px;
  display: none;
  text-align: left;
}
@media (max-width: 300px) {
  .GoalProgressBar .GoalStatus,
  .ModalGoalProgressBar .GoalStatus {
    display: block;
    margin: 5px;
  }
}
.GoalProgressBar--tooltip,
.ModalGoalProgressBar--tooltip {
  padding: 16px;
  max-width: 300px;
}
.GoalProgressBar--blocked-icon,
.ModalGoalProgressBar--blocked-icon {
  position: absolute;
  right: -25px;
  color: #ffce1e;
  text-shadow: 0 0 2px #e1e2ea;
}
.GoalProgressBar--blocked-icon-inverted,
.ModalGoalProgressBar--blocked-icon-inverted {
  color: white;
  text-shadow: none;
}
