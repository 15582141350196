.Slider {
  position: relative;
  width: 250px;
  height: 18px;
  padding: 0 21px 0 21px;
  border-radius: 500px;
  background-color: #e1e2ea;
}
.Slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 21px;
  background-color: rgba(53, 55, 74, 0.8);
  border-radius: 500px 0 0 500px;
}
.Slider .rc-slider {
  width: 100%;
  padding: 0;
}
.Slider .rc-slider,
.Slider .rc-slider-rail,
.Slider .rc-slider-track,
.Slider .rc-slider-step,
.Slider .rc-slider-handle {
  height: 100%;
}
.Slider .rc-slider,
.Slider .rc-slider-rail,
.Slider .rc-slider-step,
.Slider .rc-slider-handle {
  border-radius: 500px;
}
.Slider .rc-slider-rail {
  background-color: transparent;
}
.Slider .rc-slider-track {
  background-color: rgba(53, 55, 74, 0.8);
  border-radius: 0;
}
.Slider .rc-slider-handle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  margin-top: 0;
  margin-left: -21px;
  border: 0 none;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #35374a;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  font-size: 11px;
  font-weight: normal;
}
