/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.Conversation {
  position: relative;
}
.Conversation .ProfilePic img {
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  min-height: 60px;
}
@media (max-width: 500px) {
  .Conversation .ProfilePic img {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}
.Conversation .Conversation--reply-prompt {
  padding: 16px;
  background-color: #e6f0f7;
  color: #35374a;
  border-radius: 3px 3px 0 0;
}
.Conversation .Conversation--reply-prompt + .TextEditor .TextEditor--frame {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.Conversation--messages {
  padding-right: 12px;
  margin-bottom: 20px;
  overflow-y: auto;
}
.Conversation--reply-form {
  min-width: 0;
}
.Conversation--reply-form .ProfilePic {
  margin-right: 16px;
}
.Conversation--reply-form .Conversation--reply-text-editor {
  min-width: 0;
}
.Conversation--reply-form .DraftEditor-root {
  max-height: 200px;
  overflow-y: auto;
}
@media (max-width: 500px) {
  .Conversation--reply-form .ProfilePic,
  .Conversation--reply-form .TextEditor--emoji-toggle {
    display: none;
  }
}
.Conversation--header,
p.Conversation--header {
  color: #35374a;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  margin: 30px 0;
  white-space: pre-wrap;
  line-height: normal;
}
.Conversation--reply-action-row {
  margin-top: 0.375rem;
  padding-bottom: 25px;
  padding-top: 25px;
  max-height: 0;
  transition: 0.3s;
}
.Conversation--reply-action-row .SavingIndicator {
  margin-right: 8px;
}
.Conversation--reply-action-row-active {
  max-height: 60px;
}
.Conversation--send-button {
  margin-bottom: 0;
  padding: 0 10px;
  height: 30px !important;
  line-height: 30px !important;
}
.Conversation--reply-form-container {
  display: flex;
  align-items: center;
}
.Conversation--reply-action-row-prompted {
  margin-top: 10px;
}
.Conversation--reply-form-indented {
  margin-left: 76px;
}
.Conversation--show-more-indented {
  margin-left: 152px;
}
