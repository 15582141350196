/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.Button--plain {
  color: #21a38b;
}
.Button--plain:hover,
.Button--plain:active,
.Button--plain:focus {
  color: #1d8e79;
}
.Button--link {
  text-transform: capitalize;
}
.Button--secondary:hover {
  color: #1d8e79;
  border-color: #1d8e79;
}
