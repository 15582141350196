/** THIS IS AN AUTOGENERATED FILE **/
.GoalKeyResultList--header {
  font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-bottom: 5px;
}
.GoalKeyResultList ol {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.GoalKeyResultList--key-result-title {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  min-width: 250px;
}
.GoalKeyResultList--key-result-completion {
  min-width: 100px;
}
.GoalKeyResultList--key-result-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.GoalKeyResultList--key-result-completion .Slider {
  height: 18px;
}
