/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.GoalAlignmentSelector .PillList--pill {
  background-color: #ebebed;
  height: 30px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
}
.GoalAlignmentSelector .PillList--pill-type-cycle {
  color: #21a38b;
  border-color: #21a38b;
}
.GoalAlignmentSelector--search {
  padding-bottom: 12px;
}
.GoalAlignmentSelector--search .SearchField {
  margin-bottom: 0;
  background-color: white;
}
.GoalAlignmentSelector--alignable-goals {
  max-height: 400px;
  overflow: auto;
  background-color: white;
  border: 1px solid #e1e2ea;
  border-radius: 3px;
}
.GoalAlignmentSelector--alignable-goals-empty-state {
  color: rgba(53, 55, 74, 0.8);
  padding: 15px;
}
.GoalAlignmentSelector--alignable-goal {
  padding: 10px;
  position: relative;
}
.GoalAlignmentSelector--alignable-goal:not(:last-child) {
  border-bottom: 1px solid #e1e2ea;
}
.GoalAlignmentSelector--alignable-goal-info {
  padding: 15px 30px 8px 30px;
}
.GoalAlignmentSelector--alignable-goal-description {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 10px;
}
.GoalAlignmentSelector--alignable-goal-key-results-list {
  margin: 0;
  font-size: 16px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.GoalAlignmentSelector--alignable-goal-subtext {
  color: rgba(53, 55, 74, 0.8);
  font-size: 16px;
}
.GoalAlignmentSelector--alignable-goal-title {
  text-align: left;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.GoalAlignmentSelector--icon {
  margin-left: 8px;
  color: rgba(53, 55, 74, 0.8);
}
.GoalAlignmentSelector--icon:hover {
  color: #35374a;
}
.GoalAlignmentSelector--overlay {
  padding: 10px;
}
.GoalAlignmentSelector--alignable-goal-key-results-title {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
