.ListEditor--item {
  margin: 36px 0;
}
.ListEditor--item .RepositionableItemNumber--move {
  opacity: 0;
}
.ListEditor--item:hover .RepositionableItemNumber--move {
  opacity: 1;
}
.ListEditor--add-more-items-link {
  display: inline-block;
  font-size: 12.75px;
  color: #21a38b;
  margin-top: 6px;
  margin-bottom: 45px;
}
