.MultipleChoice {
  margin: 50px 0;
  /***** Transitions ******/
}
.MultipleChoice--question-number {
  margin-right: 5px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.MultipleChoice--question {
  margin-bottom: 10px;
  white-space: pre-wrap;
  text-align: left;
}
.MultipleChoice--question-container {
  align-items: baseline;
}
.MultipleChoice--question-title {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.MultipleChoice--question-description {
  margin-top: 10px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.MultipleChoice--question-optional {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: rgba(53, 55, 74, 0.8);
  padding-left: 0.375rem;
}
.MultipleChoice--question-shared {
  margin-bottom: 0.75rem;
  position: relative;
  display: inline-block;
}
.MultipleChoice--button {
  padding: 10px 20px;
  margin: 0;
  min-width: 0;
  min-height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #35374a;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  background: white;
  cursor: pointer;
  border: 1px solid #e1e2ea;
  text-align: center;
  -webkit-appearance: none;
}
.MultipleChoice--button-small {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.MultipleChoice--button-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  color: #d7d7db;
}
.MultipleChoice--button-icon-dot {
  position: relative;
}
.MultipleChoice--button-icon.selected {
  color: #21a38b;
}
.MultipleChoice--button-icon.selected .full {
  display: block;
}
.MultipleChoice--button-icon .empty {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.MultipleChoice--button-icon .full {
  display: none;
  position: absolute;
  top: 50%;
  transform: scale(0.5) translateY(calc(-94%));
}
.MultipleChoice--button-horizontal:first-child {
  border-radius: 5px 0 0 5px;
}
.MultipleChoice--button-horizontal:not(:last-child) {
  border-right-width: 0px;
}
.MultipleChoice--button-horizontal:last-child {
  border-radius: 0 5px 5px 0;
}
.MultipleChoice--button-vertical {
  padding-left: 60px;
  justify-content: start;
}
.MultipleChoice--button-vertical:first-child {
  border-radius: 3px 3px 0 0;
}
.MultipleChoice--button-vertical:not(:last-child) {
  border-bottom-width: 0px;
}
.MultipleChoice--button-vertical:last-child {
  border-radius: 0 0 3px 3px;
}
.MultipleChoice--text {
  width: 100%;
  word-wrap: break-word;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .MultipleChoice--text.layout.vertical {
    display: block;
  }
}
@supports (-ms-high-contrast-adjust: auto) {
  .MultipleChoice--text.layout.vertical {
    display: flex;
  }
}
.MultipleChoice--button {
  transition: 0.3s all;
  position: relative;
}
