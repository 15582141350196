/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.GoalsOverview + .GoalsOverview {
  border-top: 1px solid #e1e2ea;
}
.GoalsOverview .SkillGrid--grid {
  max-width: 880px;
}
.GoalsOverview .SkillGrid--stack-tile,
.GoalsOverview .SkillGrid--single-tile,
.GoalsOverview .SkillGrid--create-tile {
  width: 200px;
  height: 200px;
}
.GoalsOverview .SkillGrid--spacer-tile {
  width: 200px;
}
.GoalsOverview .SkillGrid--tile-surtitle {
  text-transform: capitalize;
}
.GoalsOverview .SkillGrid--tile-label {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.GoalsOverview .SkillGrid--tile-detail {
  color: #97a4ab;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.GoalsOverview--empty-content {
  color: rgba(53, 55, 74, 0.8);
  font-size: 18px;
  margin-top: 40px;
}
